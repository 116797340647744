import consumer from "./consumer";

consumer.subscriptions.create("Rail1435Channel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    $("body").append(data);
  },
});
