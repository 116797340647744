// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start()
require("channels");

import intlTelInput from "intl-tel-input";
import "@hotwired/turbo-rails";
require("chartkick");
require("chart.js");
Turbo.session.drive = false;
import InfiniteScroll from "infinite-scroll";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Splide from "@splidejs/splide";

export function toggleShowPassword(passwordInputId) {
  var passwordInput = document.getElementById(passwordInputId);
  passwordInput.type = passwordInput.type == "text" ? "password" : "text";
}

function moveToSlickSlide(slideNo) {
  $(".slider-at-bottom").slick("slickGoTo", slideNo);
}

function bouncePin(sensorName) {
  if (window.map) {
    if (window.map.markers[sensorName]) {
      window.map.markers[sensorName].setAnimation(google.maps.Animation.BOUNCE);
      setTimeout(() => window.map.markers[sensorName].setAnimation(null), 4000);
    }
  }
}

function animateMapZoomTo(map, targetZoom) {
  var timeout = (1 + map.getZoom() / 18) * 150;
  var currentZoom = arguments[2] || map.getZoom();
  if (currentZoom != targetZoom) {
    google.maps.event.addListenerOnce(map, "zoom_changed", function (event) {
      animateMapZoomTo(map, targetZoom, currentZoom + (targetZoom > currentZoom ? 1 : -1));
    });
    setTimeout(function () {
      map.setZoom(currentZoom);
    }, timeout);
  }
}

function toggleOverlay() {
  const overlay = document.getElementById("sensor-sidebar-overlay");
  if (overlay.classList.contains("hidden")) {
    overlay.classList.remove("hidden");
    overlay.parentElement.parentElement.style.overflow = "hidden";
  } else {
    overlay.classList.add("hidden");
    overlay.parentElement.parentElement.style.overflow = "auto";
  }
}

async function centerMap(sensorName, panTo = undefined) {
  if (window.map) {
    if (window.map.markers[sensorName]) {
      if (window.map.getBounds().contains(window.map.markers[sensorName].getPosition())) {
        toggleOverlay();
        animateMapZoomTo(window.map, 20);

        if (panTo !== undefined) {
          window.map.panTo(panTo);
        } else {
          window.map.panTo(window.map.markers[sensorName].getPosition());
        }
        toggleOverlay();
      } else {
        var timeout =
          (1 + window.map.getZoom() / 18) *
          100 *
          (window.map.getZoom() - window.map.initialZoom) *
          1.5;
        toggleOverlay();
        animateMapZoomTo(window.map, window.map.initialZoom);
        setTimeout(function () {
          animateMapZoomTo(window.map, 20);

          if (panTo !== undefined) {
            window.map.panTo(panTo);
          } else {
            window.map.panTo(window.map.markers[sensorName].getPosition());
          }
          toggleOverlay();
        }, timeout);
      }
    } else {
      var board = document.getElementById("sensor-board-" + sensorName);
      var boardRect = board.getBoundingClientRect();
      var msg =
        '<div class="alert alert-warning" style="z-index: 9999; left: ' +
        boardRect.x +
        "px; top: " +
        boardRect.y +
        'px; ">' +
        document.getElementById("sensor_not_on_map").innerText +
        "</div>";
      $(".navbar").append(msg);
    }
  }
}

export function selectSensor(sensorName, zoomTo = undefined) {
  if (window.map.markers[sensorName]) {
    var timeout =
      (1 + window.map.getZoom() / 18) * 100 * (window.map.getZoom() - window.map.initialZoom) * 2;
    if (
      !window.map.getBounds().contains(window.map.markers[sensorName].getPosition()) ||
      window.map.getZoom() !== 19
    ) {
      centerMap(sensorName, zoomTo);
    }
    if (
      window.map.getBounds().contains(window.map.markers[sensorName].getPosition()) &&
      window.map.getZoom() === 19
    ) {
      timeout = 0;
    }
    setTimeout(function () {
      bouncePin(sensorName);
    }, timeout);
  } else {
    var board = document.getElementById("sensor-board-" + sensorName);
    var boardRect = board.getBoundingClientRect();
    var msg =
      '<div class="alert alert-warning" style="z-index: 9999; left: ' +
      boardRect.x +
      "px; top: " +
      boardRect.y +
      'px; ">' +
      document.getElementById("sensor_not_on_map").innerText +
      "</div>";
    $(".navbar").append(msg);
    window.map.setCenter({ lat: 52.27844828492516, lng: 5.6051684079120925 });
    window.map.setZoom(8);
  }
  if (document.getElementById("current-sensor").innerText !== sensorName) {
    const prevSensor = document.getElementById(
      "sensor-board-" + document.getElementById("current-sensor").innerText
    );
    const newSensor = document.getElementById("sensor-board-" + sensorName);

    if (prevSensor) prevSensor.classList.remove("active");
    if (newSensor) newSensor.classList.add("active");

    document.getElementById("current-sensor").innerText = sensorName;

    [0, 1, 2, 3, 4, 5].forEach((number) => {
      getSensorChart(
        sensorName,
        document.getElementById("day-select-1").value,
        `myChart-${number}`,
        number + 1,
        `Sensorwaarde ${number + 1}`
      );
    });

    updateBattery(sensorName);
  }
}

export function updateBattery(sensorName) {
  var battery = document.getElementById("battery-icon");

  var url = "/get_sensor_bat_color/" + sensorName;

  var request = {
    method: "GET",
    accept: "application/json",
  };

  fetch(url, request)
    .then((response) => response.json())
    .then((result) => {
      if (
        result["success"] === "true" &&
        result.sensorName === document.getElementById("current-sensor").innerText
      ) {
        battery.setAttribute("src", "/images/Batterij_Fase_" + result["imageNo"] + ".svg");
        if (result["imageNo"] == "00") {
          document.getElementById("day-select-form").classList.add("hidden");
        } else document.getElementById("day-select-form").classList.remove("hidden");
      } else if (result["reason"] === "expired") {
        var msg =
          '<div class="alert alert-warning" style="z-index: 9999">' +
          document.getElementById("session_expired_relogin").innerText +
          "</div>";
        $(".navbar").append(msg);
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      }
    })
    .catch((error) => {
      console.log(error);
      battery.style.backgroundColor = "#e6e6e6";
      battery.setAttribute("src", "/images/Batterij_Fase_01.svg");

      if (sensorName) {
        var msg =
          '<div class="alert alert-warning" style="z-index: 9999">' +
          document.getElementById("deactivated_account").innerText +
          "</div>";
        $(".navbar").append(msg);
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      }
    });
}

export function refreshCharts(daysAgo) {
  [0, 1, 2, 3, 4, 5].forEach((number) => {
    getSensorChart(
      document.getElementById("current-sensor").innerText,
      daysAgo,
      `myChart-${number}`,
      number + 1
    );
  });
}

export function getSensorChart(sensorName, daysAgo, chartId, chartNo) {
  if (window.splideElement) window.splideElement.go(0);
  var chartloader = document.getElementById(chartId + "-loader");
  var chartbox = document.getElementById(chartId + "-box");
  chartbox.parentElement.parentElement.classList.remove("no-chart");
  var errorElement = chartbox.parentElement.querySelector(`#${chartbox.id}-error`);
  if (errorElement) errorElement.remove();
  chartloader.classList.remove("hidden");
  chartbox.classList.add("hidden");
  var url =
    "/" +
    document.getElementById("selected_locale").innerText +
    "/get_sensor_data/" +
    sensorName +
    "/" +
    daysAgo +
    "/" +
    chartNo;

  var request = {
    method: "GET",
    accept: "application/json",
  };

  fetch(url, request)
    .then((response) => response.json())
    .then((result) => {
      if (
        result["success"] === "true" &&
        result.sensorName === document.getElementById("current-sensor").innerText
      ) {
        chartloader.classList.add("hidden");
        if (result["signalName"] !== "") {
          chartbox.classList.remove("hidden");
          if (Object.keys(result["chart_data"]).length === 0) {
            var prevDate = new Date();
            var currDate = new Date();
            prevDate.setDate(
              prevDate.getDate() - parseInt(document.getElementById("day-select-1").value)
            );
            var dates = [
              prevDate.getDate().toString() + "/" + prevDate.getMonth().toString(),
              currDate.getDate().toString() + "/" + currDate.getMonth().toString(),
            ];

            renderChart(
              dates,
              [],
              chartId,
              result["signalName"],
              result["unit"],
              result["chartType"],
              result["graphInfo"]
            );
            if (chartbox.querySelector("svg"))
              chartbox.querySelector("svg").style.fill = result["color"];
          } else {
            const chartValues =
              result["correctValues"] || result["incorrectValues"]
                ? [result["correctValues"], result["incorrectValues"]]
                : result["chart_data"];

            renderChart(
              Object.keys(result["chart_data"]),
              chartValues,
              chartId,
              result["signalName"],
              result["unit"],
              result["chartType"],
              result["graphInfo"]
            );
            if (chartbox.querySelector("svg"))
              chartbox.querySelector("svg").style.fill = result["color"];
          }
        } else {
          chartbox.parentElement.parentElement.classList.add("no-chart");
        }
      } else {
        if (result["reason"] === "deactivated") {
          var msg =
            '<div class="alert alert-warning" style="z-index: 9999">' +
            document.getElementById("deactivated_account").innerText +
            "</div>";
          $(".navbar").append(msg);
          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        } else if (result["reason"] === "too long, started worker") {
          var msg =
            '<div class="alert alert-warning" style="z-index: 9999">' +
            document.getElementById("too_much_data").innerText +
            "</div>";
          $(".navbar").append(msg);
          return;
        } else if (result["reason"] === "expired") {
          var msg =
            '<div class="alert alert-warning" style="z-index: 9999">' +
            document.getElementById("session_expired_relogin").innerText +
            "</div>";
          $(".navbar").append(msg);
          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        } else {
          chartbox.parentElement.parentElement.classList.add("no-chart");
        }
      }

      if (
        chartbox.parentElement.parentElement.parentElement.querySelectorAll(
          ".splide__slide.no-chart"
        ).length >= 3
      ) {
        $(".splide__arrow--next").attr("disabled", true);
      } else {
        $(".splide__arrow--next").attr("disabled", false);
      }
    })
    .catch((error) => {
      console.log(error);
      chartloader.classList.add("hidden");
      chartbox.classList.add("hidden");
      chartbox.insertAdjacentHTML(
        "beforebegin",
        `<div class="chart-error" id="${chartbox.id}-error"><?xml version="1.0" encoding="UTF-8"?>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="75" height="auto">
          <g>
            <path style="fill:none;stroke:#ff0000;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="&#10;&#9;&#9;M209.406,345.25v-89.947c-4.589,7.1-8.118,14.933-10.393,23.272l-18.96,69.521c-11.117,40.762-36.606,76.127-71.761,99.563&#10;&#9;&#9;l-38.667,25.778"/>
            
              <line style="fill:none;stroke:#ff0000;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="209.406" y1="473.436" x2="209.406" y2="380.25"/>
            <path style="fill:none;stroke:#ff0000;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="&#10;&#9;&#9;M488.969,473.438l-38.667-25.778c-35.155-23.437-60.644-58.801-71.761-99.563l-18.96-69.521&#10;&#9;&#9;c-2.274-8.34-5.804-16.173-10.393-23.272v218.133"/>
            
              <polyline style="fill:none;stroke:#ac1a2f;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="&#10;&#9;&#9;38.562,34.258 38.562,7.5 7.5,7.5 7.5,504.5 504.5,504.5 504.5,473.438 38.562,473.438 38.562,69.258 &#9;"/>
            <path style="fill:none;stroke:#ff0000;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="&#10;&#9;&#9;M279.297,473.438V217.255c-11.519,0-23.039,2.391-33.797,7.172h0c-14.942,6.641-27.413,17.446-36.094,30.876"/>
            <path style="fill:none;stroke:#ff0000;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="&#10;&#9;&#9;M297.711,92.922l18.572-18.572c2.456-2.456,2.456-6.437,0-8.892l-9.522-9.522c-2.456-2.456-6.437-2.456-8.892,0l-18.572,18.572&#10;&#9;&#9;l-18.572-18.572c-2.456-2.456-6.437-2.456-8.892,0l-9.522,9.522c-2.456,2.456-2.456,6.437,0,8.892l18.572,18.572l-18.572,18.572&#10;&#9;&#9;c-2.456,2.456-2.456,6.437,0,8.892l9.522,9.522c2.456,2.456,6.437,2.456,8.892,0l18.572-18.572l18.572,18.572&#10;&#9;&#9;c2.456,2.456,6.437,2.456,8.892,0l9.522-9.522c2.456-2.456,2.456-6.437,0-8.892L297.711,92.922z"/>
            <path style="fill:none;stroke:#ff0000;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="&#10;&#9;&#9;M338.025,154.954c-15.308,14.498-35.98,23.39-58.728,23.39c-47.177,0-85.422-38.245-85.422-85.422S232.12,7.5,279.297,7.5&#10;&#9;&#9;s85.422,38.245,85.422,85.422c0,11.558-2.296,22.58-6.456,32.635"/>
            <path style="fill:none;stroke:#ff0000;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="&#10;&#9;&#9;M349.188,255.303c-8.68-13.43-21.151-24.235-36.094-30.876h0c-10.758-4.781-22.278-7.172-33.797-7.172"/>
          </g>
        </svg>
          ${document.getElementById("chart-error-text").innerText}
        </div>`
      );
    });
}

export function renderChart(
  labels,
  datapoints,
  id,
  signalname,
  unit = "",
  chartType = "line",
  graphInfo = {}
) {
  var ctx = document.getElementById(id).getContext("2d");
  const oldChart = $("#" + id).data("chart");
  if (!signalname && unit === "") return;
  if (oldChart) oldChart.destroy();

  const points = datapoints;
  var dataSets = [];

  if (Array.isArray(points)) {
    console.log(graphInfo.incorrectGraphInfo);
    dataSets = [
      {
        data: Object.values(points[0]).map((v) => (typeof v === "number" ? v : NaN)),
        borderColor: graphInfo.correctGraphInfo.color,
        lineTension: 0,
        showLine: graphInfo.correctGraphInfo.chartType === "line",
        fill: graphInfo.correctGraphInfo.chartType === "line",
        backgroundColor:
          graphInfo.correctGraphInfo.chartType === "line"
            ? "transparent"
            : graphInfo.correctGraphInfo.color,
        borderWidth: 0,
        pointRadius:
          graphInfo.correctGraphInfo.chartType === "line" ? 0.25 : id.includes("lg") ? 1.5 : 0.5,
        spanGaps: true,
      },
      {
        data: Object.values(points[1]).map((v) => (typeof v === "number" ? v : NaN)),
        borderColor: graphInfo.incorrectGraphInfo.color,
        lineTension: 0,
        showLine: graphInfo.incorrectGraphInfo.chartType === "line",
        fill: graphInfo.incorrectGraphInfo.chartType === "line",
        backgroundColor:
          graphInfo.incorrectGraphInfo.chartType === "line"
            ? "transparent"
            : graphInfo.incorrectGraphInfo.color,
        borderWidth: 1,
        pointRadius:
          graphInfo.incorrectGraphInfo.chartType === "line" ? 0.25 : id.includes("lg") ? 1.5 : 0.5,
        spanGaps: true,
      },
    ];
  } else {
    dataSets.push({
      data: Object.values(points),
      borderColor: "#BC4600",
      lineTension: 0,
      showLine: chartType === "line",
      fill: chartType === "line",
      backgroundColor: chartType === "line" ? "transparent" : "#BC4600",
      borderWidth: chartType === "line" ? 1 : 0,
      pointRadius: chartType === "line" ? 0.25 : id.includes("lg") ? 1.5 : 0.5,
    });
  }

  var myChart = new Chart(ctx, {
    type: "line",
    data: {
      labels: labels,
      datasets: dataSets,
    },
    options: {
      legend: { display: false },
      title: { text: signalname + " " + unit, display: true },
      backgroundColor: "#ffffff",
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,

        custom: function (tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          tooltipEl.style.zIndex = "9999";

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          const dataSetIndex =
            this._data.datasets.length === 1
              ? 0
              : this._data.datasets.findIndex(
                  (ds) =>
                    ds.data[tooltipModel.dataPoints[0].index] === tooltipModel.dataPoints[0].yLabel
                );
          // Set Text
          if (tooltipModel.body) {
            var style = "background:" + "#fff";
            style += "; border-color:" + "#acacac";
            style += "; border-width: 0.5px";
            style += "; padding: 4px";
            var innerHtml = "<thead>";
            innerHtml += `<tr><th style="${style}">${
              this._data.labels[tooltipModel.dataPoints[0].index].split("|")[1] ||
              this._data.labels[tooltipModel.dataPoints[0].index]
            }</th></tr>`;
            var datapoint =
              this._data.datasets[dataSetIndex].data[tooltipModel.dataPoints[0].index];
            datapoint = typeof datapoint === "object" ? datapoint.y : datapoint;
            innerHtml += "</thead><tbody>";

            innerHtml += `<tr><td style="${style}">${datapoint}</td></tr>`;

            innerHtml += "</tbody>";

            var tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: unit,
            },
            ticks: {
              maxTicksLimit: 6,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              maxRotation: 45,
              // suggestedMax: labels[labels.length - 1],
              minRotation: 45,
              maxTicksLimit: labels.length > 6 ? 6 : labels.length,
              callback: function (value, index, values) {
                return labels[index].split("|")[0];
              },
            },
          },
        ],
      },
    },
  });
  $("#" + id).data("chart", myChart);
}

export function openPopup(id) {
  var popup = document.getElementById(id);
  popup.style.visibility = "unset";
  popup.style.opacity = 1;
}

export function closePopup(id) {
  var popup = document.getElementById(id);
  if (id === "third-popup") {
    Array.from($("#" + id + " :input")).forEach((elem) => {
      if (elem.checked) {
        elem.click();
      }
    });
  }
  popup.style.visibility = "";
  popup.style.opacity = "";
}

export function setPeriod(period, sensorName, locale) {
  var downloadLink = document.getElementById("download-link-" + sensorName);
  downloadLink.href = `/${locale}/export/` + sensorName + "/" + period;
  openPopup("second-popup");
}

export function setFormat(format, sensorName) {
  var downloadLink = document.getElementById("download-link-" + sensorName);
  var downloadLinkHref = downloadLink.pathname.split("/");
  if (downloadLink.pathname[downloadLink.pathname.length - 1] === "/") {
    downloadLinkHref.pop();
  }
  if (downloadLinkHref.length > 5) {
    downloadLinkHref.pop();
  }
  var downloadLinkHrefNoFormat = downloadLinkHref.join("/");

  downloadLink.pathname = downloadLinkHrefNoFormat + "/" + format;

  openPopup("third-popup");
}

export function toggleExportField(field, sensorName, checkbox) {
  if (checkbox.checked) {
    addToFields(field, sensorName);
  } else {
    removeFromFields(field, sensorName);
  }
}

function addToFields(field, sensorName) {
  var downloadLink = document.getElementById("download-link-" + sensorName);
  if (downloadLink.pathname[downloadLink.pathname.length - 1] === ",") {
    downloadLink.pathname = downloadLink.pathname + field + ",";
  } else {
    downloadLink.pathname = downloadLink.pathname + "/" + field + ",";
  }
}

function removeFromFields(field, sensorName) {
  var downloadLink = document.getElementById("download-link-" + sensorName);
  downloadLink.pathname = downloadLink.pathname.replace(field + ",", "");
}

export function finishDownload(link) {
  if (!link.href.match(/(\/csv)|(\/txt)|(\/excel)/)) {
    var linkRect = link.getBoundingClientRect();
    var msg =
      '<div class="alert alert-warning" style="z-index: 9999; left: ' +
      linkRect.x +
      "px; top: " +
      linkRect.y +
      'px; ">' +
      document.getElementById("no_format_chosen").innerText +
      "</div>";
    $(".navbar").append(msg);
    return false;
  } else {
    var linkRect = link.getBoundingClientRect();
    var msg =
      '<div class="alert alert-success" style="z-index: 9999; left: ' +
      linkRect.x +
      "px; top: " +
      linkRect.y +
      'px; ">' +
      document.getElementById("download_has_started").innerText +
      "</div>";
    $(".navbar").append(msg);
    closePopup("first-popup");
    return true;
  }
}

export function verifyPassword(passwordFieldId, submitButtonId, errorId) {
  var passwordField = document.getElementById(passwordFieldId);
  var submitButton = document.getElementById(submitButtonId);
  var error = document.getElementById(errorId);

  if (
    passwordField.value.match(/((?=.*[a-z]){1,}(?=.*[A-Z]){1,}(?=.*[0-9]){1,}(?=.*\W){1,}).{12,}/)
  ) {
    error.classList.add("hidden");
    if (
      verifyPasswordConfirm(passwordFieldId, passwordFieldId + "-confirm", submitButtonId, errorId)
    ) {
      submitButton.removeAttribute("disabled");
    }
  } else {
    error.classList.remove("hidden");
    submitButton.setAttribute("disabled", true);
  }
}

export function verifyPasswordConfirm(
  passwordFieldId,
  passwordConfirmFieldId,
  submitButtonId,
  errorId
) {
  var passwordField = document.getElementById(passwordFieldId);
  var passwordConfirmField = document.getElementById(passwordConfirmFieldId);
  var submitButton = document.getElementById(submitButtonId);
  var error = document.getElementById(errorId);

  if (passwordField.value === passwordConfirmField.value) {
    error.classList.add("hidden");
    if (
      passwordField.value.match(/((?=.*[a-z]){1,}(?=.*[A-Z]){1,}(?=.*[0-9]){1,}(?=.*\W){1,}).{12,}/)
    ) {
      submitButton.removeAttribute("disabled");
    }
  } else {
    error.classList.remove("hidden");
    submitButton.setAttribute("disabled", true);
  }
  return passwordField.value === passwordConfirmField.value;
}

export function resetFilter(resetButtonId, formId) {
  $("#" + formId)
    .find("select")
    .children("option")
    .removeAttr("selected");
  $("#" + formId)
    .find("select")
    .children('option[value=""]')
    .attr("selected", "selected");
  document.getElementById(resetButtonId).form.submit();
}

export function initializeIntlTelInput(id, defaultCountry, preferredCountries) {
  const input = document.querySelector(id);
  var inputError = document.querySelector(`${id}_error`);

  window.intlTelInput = intlTelInput(input, {
    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.1/js/utils.js",
    initialCountry: "auto",
    geoIpLookup: function (callback) {
      fetch("https://ipapi.co/json")
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          callback(data.country_code);
        })
        .catch(function () {
          callback("us");
        });
    },
    preferredCountries: preferredCountries,
    nationalMode: true,
    formatOnDisplay: false,
  });

  input.onblur = () => {
    if (!window.intlTelInput.isValidNumber() && inputError) {
      inputError.classList.remove("hidden");
      inputError.querySelector("#correct-format").innerText = ` ${input.placeholder}`;
      input.setAttribute("data-vc-valid-phone", false);
      return false;
    } else {
      if (inputError) inputError.classList.add("hidden");
      input.value = window.intlTelInput.getNumber(intlTelInputUtils.numberFormat.E164);
      input.setAttribute("data-vc-valid-phone", true);
      return true;
    }
  };
}

export function splideStart() {
  window.splideElement = new Splide(".splide", {
    pagination: false,
    perPage: 3,
    perMove: 3,
    classes: {
      // Add classes for arrows.
      arrows: "splide__arrows rail1435-arrows",
    },
  }).mount();
}

export function validateLanguage(field) {
  const isValid = field.value !== "";
  const errorMessage = document.getElementById(`${field.id}_error`);

  if (isValid) {
    errorMessage.classList.add("hidden");
  } else {
    errorMessage.classList.remove("hidden");
  }

  return isValid;
}

export function validateEmail(field) {
  const isValid = field.value.match(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );
  const errorMessage = document.getElementById(`${field.id}_error`);

  if (isValid) {
    field.form.querySelector("button").removeAttribute("disabled");
    errorMessage.classList.add("hidden");
  } else {
    errorMessage.classList.remove("hidden");
  }

  return isValid;
}

export function validateName(field) {
  const isValid = field.value.includes(" ") && field.value.length >= 5;
  const errorMessage = document.getElementById(`${field.id}_error`);

  if (isValid) {
    errorMessage.classList.add("hidden");
  } else {
    errorMessage.classList.remove("hidden");
  }
  return isValid;
}

export function validatePassword(field) {
  const isValid =
    field.value.match(/\W/g) &&
    field.value.match(/\W/g).length >= 2 &&
    field.value.match(/[a-z]/g) &&
    field.value.match(/[a-z]/g).length >= 2 &&
    field.value.match(/[A-Z]/g) &&
    field.value.match(/[A-Z]/g).length >= 2 &&
    field.value.length >= 15;
  const errorMessage = document.getElementById(`${field.id}_error`);

  if (isValid) {
    errorMessage.classList.add("hidden");
  } else {
    errorMessage.classList.remove("hidden");
  }
  return isValid;
}

export function validatePasswordConfirm(field) {
  const isValid = field.value === document.getElementById("invite_wachtwoord").value;
  const errorMessage = document.getElementById(`${field.id}_error`);

  if (isValid) {
    errorMessage.classList.add("hidden");
  } else {
    errorMessage.classList.remove("hidden");
  }
  return isValid;
}

function docReady(fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

docReady(function () {
  const element = document.getElementById("sensor-sidebar");
  var infScroll = new InfiniteScroll(element, {
    path: ".paginate a[rel=next]",
    append: ".board-holder",
    elementScroll: element.parentElement,
    history: false,
    debug: false,
    prefill: false,
  });
  infScroll.loadNextPage();
  infScroll.loadNextPage();
});
